import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.ai_assistant.dashboard.main_content.scanning_card')

const ScanningChannels = ({ channels }: { channels: string[] }) => (
  <div className='ScanningChannels d-flex flex-column justify-content-center border-radius card-shadow overflow-hidden'>
    <div className='text-center p-4'>
      <img src='/images/illustrations/ai_platform_wizard_04.gif' />
    </div>

    <div className='content text-left p-4'>
      <h4 className='mb-2'>{I18N('title', { count: channels.length })}</h4>
      <p className='text-small'>{I18N('description', { channels: channels.join(', ') })}</p>
      <p className='text-small bold'>{I18N('help_text')}</p>
    </div>
  </div>
)

export default ScanningChannels
