import React from 'react'
import StatisticsCard from 'pages/ai_platform/dashboardPage/statisticsCard'
import { i18nPath } from 'utils/i18nHelpers'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'

const I18N = i18nPath('views.ai_assistant.dashboard.main_content.dashboard')

const Dashboard = () => {
  const {
    data: dashboardData,
    isLoading: isLoadingDashboardData,
  } = useFetch(API.ai.assistant.dashboardData.fetch)

  return (
    <div className='Dashboard'>
      <div className='Dashboard__statistics d-flex gap-2'>
        <StatisticsCard
          icon='/images/chatCheckIcon.svg'
          statistic={dashboardData?.questionsAnswered}
          description={I18N('statistics.questions_answered.description')}
          isLoading={isLoadingDashboardData}
          tooltip={I18N('statistics.questions_answered.tooltip')}
          isEmptyState
        />

        <StatisticsCard
          icon='/images/clockIcon.svg'
          statistic={dashboardData?.timeSaved}
          description={I18N('statistics.time_saved.description')}
          isLoading={isLoadingDashboardData}
          tooltip={I18N('statistics.time_saved.tooltip')}
          isEmptyState
        />

        <StatisticsCard
          icon='/images/chatQuestionIcon.svg'
          isEmptyState={false}
          statistic={dashboardData?.faqsCreated}
          description={I18N('statistics.faqs_created.description')}
          isLoading={isLoadingDashboardData}
          tooltip={I18N('statistics.faqs_created.tooltip')}
        />
      </div>
    </div>
  )
}

export default Dashboard
