import React from 'react'
import Activities from 'pages/ai_platform/dashboardPage/activities'
import API from 'services/api'
import useFetch from 'components/common/hooks/useFetch'
import ScanningChannels from 'pages/ai_platform/dashboardPage/scanningChannels'
import Statistics from 'pages/ai_platform/dashboardPage/statistics'
import Charts from 'pages/ai_platform/dashboardPage/charts'

const MainContent = () => {
  const { data: knowledgeIngestions } = useFetch(API.ai.assistant.knowledgeIngestions.fetchAll)

  const isFirstIngestionInProgress = knowledgeIngestions?.some(ingestion => ingestion.status === 'in_progress')
    && !knowledgeIngestions?.some(ingestion => ingestion.status === 'completed')

  const inProgressIngestion = knowledgeIngestions?.find(ingestion => ingestion.status === 'in_progress')

  return (
    <div className='d-flex flex-column gap-4'>
      {isFirstIngestionInProgress && (
        <ScanningChannels channels={inProgressIngestion?.slackChannelNames} />
      )}

      {!isFirstIngestionInProgress && (
        <>
          <Statistics />
          <Charts />
        </>
      )}

      <Activities />
    </div>
  )
}

export default MainContent
