import ClearyCard from 'components/common/card'
import CdnSvg from 'components/common/cdnSvg'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.ai_assistant.dashboard.main_content.dashboard.charts')

const Charts = () => (
  <div className='Dashboard__charts d-flex gap-2'>
    <ClearyCard title={I18N('answers_over_time.title')}>
      <div className='d-flex flex-column align-items-center gap-4 px-5 py-4'>
        <CdnSvg src='/images/illustrations/engines.svg' />
        <p className='text-center text-muted text-small'>{I18N('empty_state')}</p>
      </div>
    </ClearyCard>

    <ClearyCard title={I18N('faqs_created.title')}>
      <div className='d-flex flex-column align-items-center gap-4 px-5 py-4'>
        <CdnSvg src='/images/illustrations/engines.svg' />
        <p className='text-center text-muted text-small'>{I18N('empty_state')}</p>
      </div>
    </ClearyCard>
  </div>
)

export default Charts
